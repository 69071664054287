@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
body{
background-color: #121212;
}

.App {
  text-align: center;
}
/*dropdown styling*/
.dropdown{
font-family: 'Libre Baskerville';
color: white;
display: block;
margin: 0;

}

.dropdown-menu {
list-style: none;
margin: .5rem;
padding:1%;
width: 100%;
text-decoration:none;
}

a{
color: white;

}
.dropdown-toggle{
display: flex;
font-family: 'Libre Baskerville';
background-color: #121212;
font-size: 1rem;
padding: 1%;
width: 50%;
text-align:left;
text-decoration:none;
flex-direction:row;
color:white;
border-style: solid;
border-color: white;

}

/*label and sub bullet styling*/
.label{
font-size:1.5rem;
font-weight: bold;

}

.subBullet{
font-size:1rem;
padding-top:.5%;
padding-bottom: .5%;
font-weight: normal;
padding-right: 1%;
}
/* iframes in drop downs */

iframe{
radius:10px;
border: 1px solid white;
border-radius: 10px;
width: auto;
height: auto;
background-color:#121212;
}

.plus:hover {color: #5A5A5A}
.plus{
padding: 0;
margin: 0;
font-size:1.5rem;
font-weight: bold;
margin-left: auto;
}



/*Drop Down mobile menu*/
@media only screen and (max-width: 768px) {
.dropdown-menu {
list-style: none;
margin: 0;
padding:1%;
width: 100%;
text-decoration:none;
}

a{
color: white;

}
.dropdown-toggle{
font-family: 'Libre Baskerville';
background-color: #121212;
font-size: 1rem;
padding: 1%;
width: 100%;
text-align:left;
text-decoration:none;
color:white;
border-style: solid;
border-color: white;
text-decoration:none;


}
}

/*home page styling*/

.homep{
font-family: 'Libre Baskerville',Bold;
color: white;
display: flex;
flex-direction:row;
align-items: center;
justify-content: space-between;
padding: 2rem;
}

homep ul {
padding: 0rem;
margin: 0;
list-style: none;
gap: 1rem;
}
.homep button:hover {background-color: #5A5A5A}
.homep button{
font-family: 'Libre Baskerville',Bold;
background-color: #121212;
font-size: 1.5rem;
color: white;
padding: 1.25%;
border-style: solid;
border-color: white;
margin: .5rem;
text-decoration:none;
}

.homep, img {

display: inline-block;}

homep .container {
  display: flex;
  align-items: center;
  justify-content: center
}

.image-home{
float: right;
border-top-left-radius: 90%;
border: 1px solid white;
width: 55%;
}

/*for mobile styling of home page*/
@media only screen and (max-width: 768px) {
  homep .container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
  }

  .image-home{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
}

/*vsco image*/
.imgVSCO:hover {box-shadow: #5A5A5A}

.imgVSCO{
position: absolute;
height: 25%;
float: right;
padding:2rem;
}

/*atoms/bits image*/
.imgNYC{
margin: 1.5rem;
float: right;
border-top-left-radius: 80%;
border: 1px solid white;

}
/*atoms/bits image for mobile*/
@media only screen and (max-width: 768px) {
.imgNYC{
margin: 1.5rem;
float: right;
border-top-left-radius: 80%;
border: 1px solid white;
width: 85%;
}
}

/*nav bar*/
.nav .tylerlasicki:hover {color: #5A5A5A}
.nav .linkedin:hover {color: #5A5A5A}
.nav .twitter:hover {color: #5A5A5A}
.nav .substack:hover {color: #5A5A5A}
.nav{
border-bottom: 1px solid white;
color: white;
display: flex;
justify-content: space-between;
align-items: stretch;
padding: 01rem;
}

.nav ul {
padding: 0;
margin:0;
list-style: none;
display: flex;
}

.nav a {
height: 100%;
color: inherit;
text-decoration:none;
padding: .25rem;
margin:0;
}

h1{
font-family: 'Libre Baskerville',Bold;
font-size: 2rem;
color: white;
}

p{
color: white;
font-family: 'Libre Baskerville';
font-size: 1rem;
padding-top: 2rem;
padding-bottom: 0rem;
font-size: .8rem;
text-align: center;
}
p1{
color: white;
font-family: 'Libre Baskerville';
font-size: 1rem;
position: fixed;
bottom: 5%;
transform: translate(150%,100%);
z-index: 1;

}
.fade-in {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.fade-in.show {
  opacity: 1;
}

/*sub module styling*/

.BsoSection{
margin:7rem;
border: 1px dashed white;
border-radius: 10px;
padding: 1rem;
width: auto;

}

.BsoSection h2{
position: flex;
font-family: 'Libre Baskerville';
font-size:1rem;
float: center;
color: white;
}

/*sub module for mobile*/
@media only screen and (max-width: 768px) {
  .BsoSection{
  border: 1px dashed white;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
  float: center;
  }

  .BsoSection h2{
  font-family: 'Libre Baskerville';
  font-size:1rem;
  text-position: center;
  color: white;
  }

.substackiFrame{
radius:10px;
padding: 1rem;
border: 1px solid white;
border-radius: 10px;
background-color: white;
width: auto-10%;
height: auto;
scrolling: no;
background-color:#121212;
}
}
/*footer styling*/

.footerNote{
font-size:.75rem;
text-align: center;
padding-bottom: 0;
left:20%;
right:20%;

}
